import appDB, { ICountry } from './appDatabase';

const DATA_VERSION_NUMBER_KEY = 'data_version_number';

export const getDataVersionNumber = () => localStorage.getItem(DATA_VERSION_NUMBER_KEY);
export const updateDataVersionNumber = (dataVersionNumber: string) =>
  localStorage.setItem(DATA_VERSION_NUMBER_KEY, dataVersionNumber);

export const sameDataVersionNumber = (dataVersionNumber: string) =>
  getDataVersionNumber() === dataVersionNumber;

export const sameCountry = async (countries: ICountry[]) => {
  if (!countries || countries.length === 0) {
    return false;
  }
  const localCountry = await appDB.countries.toCollection().first();
  return localCountry && localCountry.code === countries[0].code;
};
