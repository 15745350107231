import jwt_decode from 'jwt-decode';

interface AccessToken {
  exp: number;
}

const TOKEN_KEY = 'token';

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const updateToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

export const tokenHasExpired = (token: string) => {
  const parsedToken = jwt_decode<AccessToken>(token);
  if (!parsedToken.exp) {
    return true;
  }

  // Less than 10 seconds remaining => token has expired
  const now = new Date().getTime() / 1000;
  return parsedToken.exp - now < 10;
};
