const ROUTES = {
  LOGIN: '/login',
  NEW_QUOTE: '/new-quote',
  CHARACTERISTICS: '/characteristics',
  QUOTE: '/quote',
  PRICING_RANGE: '/pricing-range',
  SHORTBREAK_DETAILS: '/shortbreak-details',
  ALL_QUOTES: '/all-quotes',
  COMPARISON_DETAILS: '/comparison-details',
};

export default ROUTES;
