import { createAsyncAction } from 'typesafe-actions';

export const loginUser = createAsyncAction(
  'Login/USER_LOGIN_REQUEST',
  'Login/USER_LOGIN_SUCCESS',
  'Login/USER_LOGIN_FAILURE',
)<
  {
    username: string;
    password: string;
  },
  {
    token: string;
  },
  {
    errorMessage: string;
  }
>();

export const logout = createAsyncAction(
  'Login/USER_LOGOUT/REQUEST',
  'Login/USER_LOGOUT/SUCCESS',
  'Login/USER_LOGOUT/FAILURE',
)<undefined, undefined, undefined>();

export default {
  loginUser,
};
