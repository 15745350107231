import { compose, lifecycle, withState } from 'recompose';
import appDatabase from 'services/appDatabase';

interface IIntermediateProps {
  taxRate: number;
  setTaxRate: (amount: number) => void;
}

const withTaxRate = compose<any, any>(
  withState('taxRate', 'setTaxRate', 0),
  lifecycle<IIntermediateProps, {}>({
    componentDidMount() {
      appDatabase.countries
        .toCollection()
        .first()
        .then((country) => this.props.setTaxRate(country ? country.taxRate : 0));
    },
  }),
);

export default withTaxRate;
